import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import { Hub, Logger } from 'aws-amplify';
Amplify.configure(awsconfig);

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
    case 'signIn':
	logger.info('user ' + data.payload.data.username + ' signed in');
	break;
    case 'signUp':
	logger.info('user signed up');
	break;
    case 'signOut':
	logger.info('user signed out');
	break;
    case 'signIn_failure':
	logger.error('user sign in failed');
	break;
    case 'tokenRefresh':
	logger.info('token refresh succeeded');
	break;
    case 'tokenRefresh_failure':
	logger.error('token refresh failed');
	break;
    case 'configured':
	logger.info('the Auth module is configured');
	break;
    default:
	logger.error("wrong status");
	break;
    }
}

Hub.listen('auth', listener);
const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
      <div className="App">
          <div>Hello, {user.username}</div>
          <AmplifySignOut />
      </div>
    ) : (
      <AmplifyAuthenticator />
  );
}

export default AuthStateApp;
