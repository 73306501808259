/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:207b81a5-2207-42ce-9f0d-5307443354e9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vOxnuJ9GU",
    "aws_user_pools_web_client_id": "63unfgq6qq6pngra0rgg4dokbj",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "zohoredirect",
            "endpoint": "https://5a63fa5qzc.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
